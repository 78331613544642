/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "deliverectRest",
            "endpoint": "https://nx6sc7rhhk.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "integration",
            "endpoint": "https://s8vqatmlng.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "invoiceManagerRest",
            "endpoint": "https://f4mpwrp43c.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "stripeRest",
            "endpoint": "https://pi3tavxhu8.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zo4mqp4nlbdw7nc6fyertm6ptu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "eu-west-1:01cb0ffe-5458-4c20-9469-85a4fa7700df",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_YkZPkxUFE",
    "aws_user_pools_web_client_id": "5i4ggga0431bj9c44q7ndfrs4k",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wannapay-bucket142101-master",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "predictions": {
        "convert": {
            "translateText": {
                "region": "eu-west-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "it",
                    "targetLanguage": "en"
                }
            }
        }
    }
};


export default awsmobile;
